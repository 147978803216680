import React from 'react';
import { datadogRum } from '@datadog/browser-rum-slim';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';

import { throttle } from '../utils/throttle';

export interface SessionTimerOptions {
  /**
   * The interval in seconds at which the session timer checks for user activity.
   */
  checkInterval: number;

  /**
   * The duration in seconds for the session to last.
   */
  sessionDuration: number;

  firmId: string;
  nodeId: string;

  router: NextRouter;
}

/**
 * The SessionTimer class is responsible for managing a user's session.
 * It keeps track of the user's last activity and determines when the session should be refreshed or expired.
 *
 * Note: This class is a singleton and can only be instantiated once.
 */
export class SessionTimer {
  static instance: null | SessionTimer = null;

  private sessionDuration: number = 60 * 1000; // 10 minutes
  private checkInterval: number = 1000;
  private interval: NodeJS.Timeout | null = null;
  private lastActivity: number | null = null;
  private expiresAt: number | null = null;
  private firmId: string = '';
  private nodeId: string = '';
  private router: NextRouter | null = null;
  activityType: 'scroll' | 'mouse-movement' | 'keyboard' | 'init' = 'init';

  constructor({ checkInterval, firmId, nodeId, sessionDuration, router }: SessionTimerOptions) {
    if (SessionTimer.instance) {
      return SessionTimer.instance;
    }
    SessionTimer.instance = this;

    this.activityType = 'init';

    this.sessionDuration = sessionDuration * 1000;

    this.checkInterval = checkInterval;
    this.firmId = firmId;
    this.nodeId = nodeId;

    // refresh the session intially so the timers are close to in sync.
    this.refreshSession();

    this.interval = setInterval(this.handleInterval, this.checkInterval * 1000);
    this.router = router;
  }

  userActivity = (listenerType: 'scroll' | 'mouse-movement' | 'keyboard') => {
    this.lastActivity = Date.now();
    this.activityType = listenerType;
  };

  private refreshSession = async () => {
    const currentActivity = this.lastActivity!;

    const currentExpiry = this.expiresAt!;

    try {
      this.lastActivity = null;
      this.expiresAt = Date.now() + this.sessionDuration;

      const response = await fetch(`/api/baq?firmId=${this.firmId}&nodeId=${this.nodeId}`, {
        mode: 'no-cors',
      });

      if (!response.ok) {
        this.lastActivity = null;
        await this.destroy('Refresh endpoint returned a non-200 status code');
      }
      if (this.activityType !== 'init') {
        const diff = currentExpiry - currentActivity;

        const humanFriendlyDiff = `User was active (${this.activityType}) ${
          diff > 0 ? diff / 1000 : 1
        } seconds before the session expired.`;
        datadogRum.addAction('session_refreshed', {
          activity: new Date(currentActivity).toISOString(),
          expiry: new Date(currentExpiry).toISOString(),
          message: humanFriendlyDiff,
        });
      }
    } catch (e) {
      if (!(e instanceof Error) && (typeof e === 'string' || e === undefined)) {
        e = new Error(e);
      }
      await this.destroy('Network error attempting to invoke the session refresh endpoint');
      if (e instanceof Error) {
        datadogRum.addAction('Network error attempting to invoke the session refresh endpoint', {
          activity: new Date(currentActivity).toISOString(),
          expiry: new Date(currentExpiry).toISOString(),
          message: e.message,
        });
      }
    }
  };

  private handleInterval = async () => {
    const now = Date.now();

    if (this.expiresAt && now > this.expiresAt) {
      if (!this.lastActivity) {
        // there was no activity within the expiry window, destroy the session.
        return await this.destroy('No activity detected within the expiry window');
      }

      const activityDiff = (this.lastActivity ?? 0) + this.sessionDuration - this.expiresAt;
      const activityDetectedPriorToExpiry = activityDiff > 0;

      if (activityDetectedPriorToExpiry) {
        // there was activity within the expiry window, refresh the session.
        return await this.refreshSession();
      } else {
        // activity was detected after the expiry window, destroy the session.
        return await this.destroy('Activity detected after the expiry window');
      }
    }
  };

  private clearInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  public destroy = async (reason: string) => {
    datadogRum.addAction('session_expired', {
      message: reason,
    });

    // clean up our timers and redirect the user to the logout route.
    this.clearInterval();
    SessionTimer.instance = null;

    this.router?.replace('/api/auth/logout');
  };
}

export const useSessionTimer = (
  initRef: React.MutableRefObject<boolean>,
  hasSession: boolean,
  firmId: string,
  nodeId: string,
  isPlaywright: boolean
) => {
  const sessionRef = React.useRef<SessionTimer>();
  const router = useRouter();

  React.useEffect(() => {
    if (isPlaywright) {
      return;
    }
    const ping = throttle((activityType: 'scroll' | 'mouse-movement' | 'keyboard') => {
      sessionRef.current?.userActivity(activityType);
    }, 1000);

    const cleanup = () => {
      window.removeEventListener('keydown', () => ping('keyboard'));
      window.removeEventListener('pointermove', () => ping('mouse-movement'));
      window.removeEventListener('scroll', () => ping('scroll'));
      console.log('cleanup destroying');
      sessionRef.current?.destroy('Session timer hook is unmounting.');
      initRef.current = false;
    };

    // we haven't initialised the timer yet and we are logged in.
    if (!initRef.current && hasSession) {
      sessionRef.current = new SessionTimer({
        checkInterval: 1,
        firmId,
        nodeId,
        sessionDuration: Number(process.env.NEXT_PUBLIC_SESSION_DURATION_SECONDS ?? 590), // shave off 10 seconds to account for network latency.
        router,
      });

      window.addEventListener('keydown', () => ping('keyboard'));
      window.addEventListener('pointermove', () => ping('mouse-movement'));
      window.addEventListener('scroll', () => ping('scroll'));

      initRef.current = true;
    }

    // we have initialised the timer previously, but we are now logged out, so we need to destroy the timer.
    if (initRef.current && !hasSession) {
      cleanup();
    }
  }, [initRef, hasSession, firmId, nodeId]);

  return null;
};
